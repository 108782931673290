import { Link } from "react-router-dom";
import styled from "styled-components";
import airplane_logo from "../assets/airplane_logo.png";
import { ReactComponent as ArrowLeft } from "../assets/arrow-left.svg";

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 1.5em;
`;

const AirplaneLogo = styled.img`
  width: 6em;
`;

const BackButton = styled(Link)`
  text-decoration: none;
  background-color: black;
  color: white;
  padding: 8px;
  font-size: 16px;
  margin: 15px;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
`;

const StyledArrowLeft = styled(ArrowLeft)`
  fill: white;
  height: 20px;
  width: 20px;
`;

export const Header = ({ canNavigateBack }: { canNavigateBack?: boolean }) => (
  <HeaderContainer>
    {canNavigateBack && (
      <BackButton to="/">
        <StyledArrowLeft />
        BACK
      </BackButton>
    )}
    <AirplaneLogo src={airplane_logo} />
    <h1>The Names We Change</h1>
  </HeaderContainer>
);
