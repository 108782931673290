import Qais from "../assets/01_Qais.jpg";
import Hend from "../assets/02_Hend.jpg";
import Karan from "../assets/03_Karan.jpg";
import Katya from "../assets/04_Katya.jpg";
import Pedram from "../assets/05_Pedram.jpg";
import Prerna from "../assets/06_Prerna.jpg";
import Chitra from "../assets/07_Chitra.jpg";
import Nouf from "../assets/08_Nouf.jpg";
import Shaban from "../assets/09_Shaban.jpg";
import Jimmy from "../assets/10_Jimmy.jpg";
import Breck from "../assets/11_Breck.jpg";
import Silvana from "../assets/12_Silvana.jpg";

export const INTERVIEWS = {
  "1": {
    name: "Qais",
    videoSrc:
      "https://storage.googleapis.com/names-we-change-videos/01_Qais.mov",
    image: Qais,
    fullName: "Qais Munhazim",
    otherNames: "Ahmad Qais, Liam, Blanche, Noor Jebran, Kamran",
    homeCountry: "Afghanistan",
    hostCountry: "America",
    agency: "Resistance, Sarcasm, Disruption, Deflection, Romance",
  },
  "2": {
    name: "Hend",
    videoSrc:
      "https://storage.googleapis.com/names-we-change-videos/02_Hend.mov",
    image: Hend,
    fullName: "Hend Al Mansour",
    otherNames: "Hind, Suzy, Daughter of Earth, Bent-ol-Arz",
    homeCountry: "Saudi Arabia",
    hostCountry: "America",
    agency: "Quiet Resilience, Authenticity, Grace, Self Awareness",
  },
  "3": {
    name: "Karan",
    videoSrc:
      "https://storage.googleapis.com/names-we-change-videos/03_Karan.mov",
    image: Karan,
    fullName: "Karan Tabrizi",
    otherNames: "Karan, Karen, Kevin, AJ",
    homeCountry: "Iran",
    hostCountry: "America",
    agency: "Pragmatism, Analytical, Sincere",
  },
  "4": {
    name: "Katya",
    videoSrc:
      "https://storage.googleapis.com/names-we-change-videos/04_Katya.mov",
    image: Katya,
    fullName: "Katya Roberts",
    otherNames: "Yekaterina, Katerina, Katrina, Kate",
    homeCountry: "Ukraine",
    hostCountry: "America",
    agency: "Reclamation, Renewal, Emergence",
  },
  "5": {
    name: "Pedram",
    videoSrc:
      "https://storage.googleapis.com/names-we-change-videos/05_Pedram.mov",
    image: Pedram,
    fullName: "Pedram Baldari",
    otherNames: "Sol, Tedram, Ted, Ped, Pedy, Pedro, Mohammad",
    homeCountry: "Iran",
    hostCountry: "America",
    agency: "Resistance, Rebellion, Independence, Satire, Irreverence",
  },
  "6": {
    name: "Prerna",
    videoSrc:
      "https://storage.googleapis.com/names-we-change-videos/06_Prerna.mov",
    image: Prerna,
    fullName: "Prerna",
    otherNames:
      "Prerna Jumbunathan, Pichamma, Prerna Prerna, Prerna Unknown, FNU, Not Applicable, NLN, .",
    homeCountry: "India",
    hostCountry: "America",
    agency: "Inspiration, Irreverence, Humor, Resistance",
  },
  "7": {
    name: "Chitra",
    videoSrc:
      "https://storage.googleapis.com/names-we-change-videos/07_Chitra.mov",
    image: Chitra,
    fullName: "Chitra Vairavan",
    otherNames: "CV, Chithri, Chitara, Chiti, Cheech, Chit",
    homeCountry: "India",
    hostCountry: "America",
    agency: "Self-assurance, Reclamation, Endearment",
  },
  "8": {
    name: "Nouf",
    videoSrc:
      "https://storage.googleapis.com/names-we-change-videos/08_Nouf.mov",
    image: Nouf,
    fullName: "Nouf Saleh Al-Masrafi",
    otherNames:
      "Thekra (memory), Nough, Noof, Noaf, Nofa, Ova, Zahrat-al-Yaman (flower of Yemen)",
    homeCountry: "Yemen",
    hostCountry: "America",
    agency: "Mindfulness, Acceptance, Inquisitiveness",
  },
  "9": {
    name: "Shaban",
    videoSrc:
      "https://storage.googleapis.com/names-we-change-videos/09_Shaban.mov",
    image: Shaban,
    fullName: "Shaban Nazarian",
    otherNames:
      "Siobhán, Shazam, Shabby, Bill or any simple name made up on the spot",
    homeCountry: "Iran",
    hostCountry: "America",
    agency: "Patience, Awakening, Acceptance, Historic Witness",
  },
  "10": {
    name: "Jimmy",
    videoSrc:
      "https://storage.googleapis.com/names-we-change-videos/10_Jimmy.mov",
    image: Jimmy,
    fullName: "James David Cooper",
    otherNames: "Jimmy, J, Matt, Jim, Ev, Evelyna Arnold, Jamie, Beaux, Bo",
    homeCountry: "America",
    hostCountry: "America",
    agency: "Reclamation, Incisive Wit, Critical Examination",
  },
  "11": {
    name: "Breck",
    videoSrc:
      "https://storage.googleapis.com/names-we-change-videos/11_Breck.mov",
    image: Breck,
    fullName: "Elizabeth Breck Hickman Pine",
    otherNames:
      "Breck Ellen Hickman, Breck, Breckie, Brock, Brett, Elizabeth, Liz, Boomer, Boomer Cat, Breakfast, Little Hickman, Breck the ok",
    homeCountry: "America",
    hostCountry: "America",
    agency: "Self-awareness, Experimental, Reassurance, Humor",
  },
  "12": {
    name: "Silvana",
    videoSrc:
      "https://storage.googleapis.com/names-we-change-videos/12_Silvana.mov",
    image: Silvana,
    fullName: "Silvana Agostoni",
    otherNames:
      "Silvy or Silvi or whatever spelling, Ivana, Ilvana, Savanna, Sylvia",
    homeCountry: "Mexico-Italy",
    hostCountry: "America",
    agency: "Reliance, Dark humor, Love",
  },
};

export type InterviewId = keyof typeof INTERVIEWS;

export const INTERVIEW_IDS = Object.keys(INTERVIEWS) as Array<InterviewId>;
