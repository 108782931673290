import { Helmet } from "react-helmet";
import { HashRouter, Route, Routes } from "react-router-dom";
import { createGlobalStyle } from "styled-components";
import { InterviewPage } from "./pages/InterviewPage";
import { MainPage } from "./pages/MainPage";

const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'ABeeZee',Helvetica,Arial,Lucida,sans-serif!important;
    color: #ffffff;
    background-color: #12508F!important;
    // background-color: #0C71C3!important;
  }
  button {
    font-family: inherit;
    cursor: pointer;
  }
`;

export const AppRoutes = () => {
  return (
    <HashRouter>
      <GlobalStyle />
      <Helmet>
        <title>The names we change |</title>
        <meta charSet="utf-8" />
      </Helmet>
      <Routes>
        <Route path="/interviews/:id" element={<InterviewPage />}></Route>
        <Route path="/" element={<MainPage />}></Route>
      </Routes>
    </HashRouter>
  );
};
