import { Link } from "react-router-dom";
import styled from "styled-components";
import { Header } from "../components/Header";
import {
  InterviewId,
  INTERVIEWS,
  INTERVIEW_IDS,
} from "../constants/interviews";

const InterviewsGrid = styled.div`
  display: flex;
  flex-direction: column;
  margin: 3em;
  flex: 1;
  overflow: auto;
  gap: 1em;
`;

const InterviewsRow = styled.div`
  display: flex;
  flex-direction: row;
  height: 30%;
  justify-content: center;
  width: 100%;
`;

const InterviewTile = styled(Link)`
  display: flex;
  flex: 1;
  padding: 1em;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: #ebebeb;
`;

const InterviewTileImage = styled.img`
  height: 100%;
  max-width: 100%;
  object-fit: contain;
  padding: 10px;
`;

const MainPageContainer = styled.div`
  display: flex;
  height: 100vh;
  flex-flow: column;
`;

const Interview = ({ interviewId }: { interviewId: InterviewId }) => {
  const interview = INTERVIEWS[interviewId];
  return (
    <InterviewTile to={`/interviews/${interviewId}`}>
      <InterviewTileImage src={interview.image} />
      {interview.name}
    </InterviewTile>
  );
};

export const MainPage = () => {
  return (
    <MainPageContainer>
      <Header />
      <InterviewsGrid>
        <InterviewsRow>
          {INTERVIEW_IDS.slice(0, 4).map((interviewId) => (
            <Interview key={interviewId} interviewId={interviewId} />
          ))}
        </InterviewsRow>
        <InterviewsRow>
          {INTERVIEW_IDS.slice(4, 8).map((interviewId) => (
            <Interview key={interviewId} interviewId={interviewId} />
          ))}
        </InterviewsRow>
        <InterviewsRow>
          {INTERVIEW_IDS.slice(8, 12).map((interviewId) => (
            <Interview key={interviewId} interviewId={interviewId} />
          ))}
        </InterviewsRow>
      </InterviewsGrid>
    </MainPageContainer>
  );
};
