import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { InterviewId, INTERVIEWS } from "../constants/interviews";
import { Header } from "../components/Header";

const InterviewPageContainer = styled.div`
  display: flex;
  flex-flow: column;
  height: 100vh;
`;

const InterviewImageContainer = styled.div`
  max-height: 70%;
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 1em;
`;

const InterviewVideoContainer = styled.div<{ $imageSrc: string }>`
  width: 100%;
  aspect-ratio: 16 / 9;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${({ $imageSrc }) => $imageSrc});
`;

const InterviewVideo = styled.video`
  visibility: hidden;
  width: 100%;
`;

const WatchButton = styled.button`
  background-color: #8e2319;
  padding: 0.75em 2.5em;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  border-radius: 3px;
`;

const InterviewContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  margin: 3em;
  gap: 2em;
  flex: 1;
`;

const InterviewWatchContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  flex: 60%;
  height: 100%;
`;

const InterviewDescriptionContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 40%;
`;

const InterviewDescriptionItem = styled.div`
  padding-top: 1em;
`;

export const InterviewPage = () => {
  const { id } = useParams<{ id: InterviewId }>();
  const videoEl = useRef<HTMLVideoElement>(null);

  const [videoPlaying, setVideoPlaying] = useState(false);

  const onWatchButtonClick = () => {
    if (videoEl.current) {
      setVideoPlaying(true);
      const video: any = videoEl.current;
      try {
        // Check if the browser supports fullscreen
        if (video.webkitRequestFullscreen) {
          video.webkitRequestFullscreen();
        } else if (video.mozRequestFullScreen) {
          video.mozRequestFullScreen();
        } else if (video.requestFullscreen) {
          video.requestFullscreen();
        } else if (video.requestFullScreen) {
          video.requestFullScreen();
        } else if (video.webkitRequestFullScreen) {
          video.webkitRequestFullScreen();
        }

        videoEl.current.play();
        videoEl.current.style.visibility = "visible";
      } catch (e) {
        console.log("ERROR PLAYING VIDEO", e);
      }
    }
  };

  if (!id) return null;

  const interview = INTERVIEWS[id];

  return (
    <InterviewPageContainer>
      <Header canNavigateBack />
      <InterviewContentContainer>
        <InterviewWatchContainer>
          <InterviewImageContainer>
            <InterviewVideoContainer
              $imageSrc={videoPlaying ? "" : interview.image}
            >
              <InterviewVideo controls ref={videoEl}>
                <source src={interview.videoSrc} type="video/mp4" />
              </InterviewVideo>
            </InterviewVideoContainer>
            <WatchButton onClick={onWatchButtonClick}>Watch</WatchButton>
          </InterviewImageContainer>
        </InterviewWatchContainer>
        <InterviewDescriptionContainer>
          <h1>{interview.name}</h1>
          <InterviewDescriptionItem>
            <b>Name: </b>
            {interview.fullName}
          </InterviewDescriptionItem>
          <InterviewDescriptionItem>
            <b>Other Names: </b>
            {interview.otherNames}
          </InterviewDescriptionItem>
          <InterviewDescriptionItem>
            <b>Home Country: </b>
            {interview.homeCountry}
          </InterviewDescriptionItem>
          <InterviewDescriptionItem>
            <b>Host Country: </b>
            {interview.hostCountry}
          </InterviewDescriptionItem>
          <InterviewDescriptionItem>
            <b>Agency: </b>
            {interview.agency}
          </InterviewDescriptionItem>
        </InterviewDescriptionContainer>
      </InterviewContentContainer>
    </InterviewPageContainer>
  );
};
